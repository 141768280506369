.obat-pages
{
    margin: 40px 0px;
    heading h6
    {
        font-weight: bold;
        font-size: 0.7em;
        color: $color-secondary;
        text-align: center;
    }

    heading h1
    {
        font-weight: 200;
        font-size: 2.5em;
        text-align: center;
        color: $color-primary;
    }

    img
    {
        border-radius: $border-radius;
    }

    p
    {
        text-align: justify;
        line-height: 2em;
        font-family: $font-primary;
        margin: 10px 0px 20px 0px;
    }

    ol,ul
    {
        list-style-type: none;
    }

    ol
    {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        li
        {
            width: 30%;
            min-width: 150px;
        }
    }

    li
    {
        margin: 10px 0px;
        background: linear-gradient(90deg, rgba($color-primary-light,0.7), transparent);
        border-radius: $border-radius;
        padding: 10px 20px;
    }

    h4
    {
        font-weight: 200;
        color: $color-primary;
        font-size: 1.5em;
    }

    .alert
    {
        margin: 10px 0px;
        padding: 10px;
        background: rgba($color-primary-light,0.7);
        border-radius: $border-radius;
    }

    .box
    {
        padding: 20px;
        border-radius: $border-radius;
        //border: 2px solid $color-secondary-light;
        box-shadow: 0px 0px 20px rgba($color-secondary-light, 0.4);
    }

    .a-boxes
    {
        display: flex;
        justify-content: center;
        align-items: stretch;
        flex-wrap: wrap;
        h1,h2,h3,h4,h5
        {
            text-align: center;
        }
        *
        {
            text-align: center;
            margin: 0px auto;
        }
        a
        {
            padding: 10px;
            width: 30%;
            background-color: rgba($color-secondary-bright,0.1);
            border: 2px solid rgba($color-secondary-light,0.2) !important;
            border-radius: 4px;
            margin: 10px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        badge
        {
            background-color: rgba($color-secondary-light,0.2);
            color: $color-secondary;
            padding: 4px 20px;
            margin: 5px 0px;
            border-radius: $border-radius;
        }
    }
}


