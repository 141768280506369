.obat-hero-header
{
    width: 100%;
    //background-image: url("../../../js/website/img/hero_image.jpg");
    background-image: url("../../img/hero_image3.jpg");
    background-position: center;
    background-size: cover;
    background-position: center right;

    .obat-hero-header__body
    {
        background: linear-gradient(0deg,rgba(darken($color-secondary,40%),0.9), rgba(darken($color-secondary,30%),0.6));
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: center;
        padding: 200px 0px 50px 0px;
    }
    .obat-hero-header__body .header__info
    {
        width: 80%;
        margin-bottom: 100px;
        min-width: 320px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
    .obat-hero-header__body .header__info-content
    {
        width: 85%;
        color: $color-bright;
    }
    .obat-hero-header__body .header__info-content h1
    {
        font-size: $font-size-super-heading;
        line-height: 4.5rem;
        font-weight: 200;
        font-family: font-primary-bold;
        margin: 10px 0px;
    }
    .obat-hero-header__body .header__info-content h1 b
    {
        color: $color-primary;
    }
    .obat-hero-header__body .header__info-content p
    {
        font-size: $font-size-regular;
        margin-bottom: 10px;
    }

    .obat-hero-header__body .header__clip
    {
        display: flex;
        justify-content: flex-end;
        width: 40%;
    }

    .obat-hero-header__body .header__clip .header__clip-target
    {
        -webkit-clip-path: url(#svgPath);
        clip-path: url(#svgPath);
        transform: scale(0.9,0.9);
        background-image: url("../../img/dream.jpg");
        width: 100%;
        height: 615px;
        background-color: white;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: right top;
    }

    svg.obat-hero-dream
    {
        width: 0px;
    }

    .obat-hero-header__body .header__info .header__info-cta
    {
        position: absolute;
        z-index: 2;
        margin-top: 4rem;
        min-width: 320px;
        padding: 2px;
        background: rgba(255,255,255,1);
        //border: 4px solid rgba(255,255,255,0.1);
        border-radius: 5px;
        color: #000;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        align-items: center;
        input,select
        {
            min-width: 200px;
            height: 3.5rem;
            border: 0px;
            background-color: transparent;
            margin: 0px;
            border-radius: 2px;
        }
        button
        {
            border-radius: 4px !important;
            height: 4rem;
        }
    }
    .obat-hero-header__body .header__info .header__info-cta div[role='group']
    {
        margin: 2px 4px;
    }

    svg.wave
    {
        background-color: rgba(darken($color-secondary,40%),0.9);
    }
    svg path
    {
        fill: white;
    }
}

