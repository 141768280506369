@mixin btn-style($background,$color)
{
    height: auto !important;
    padding: 15px !important;
    border-radius: 4px;
    background: $background !important;
    color: $color;

}

.btn-primary-cta
{
    @include btn-style($color-primary,$color-bright);
    &.small-margin
    {
        margin: 4px ;
    }
    &.margin-small
    {
        margin: 6px;
    }
    &.margin-10
    {
        margin: 20px 0px;
    }
    &:hover
    {
        box-shadow: -8px 8px 0px rgba($color-primary,0.3);
    }
    i
    {
        margin-left: 10px;
    }
}

.btn-secondary-cta
{
    @include btn-style(auto,$color-primary-dark);
    &:hover
    {
        box-shadow: -8px 8px 0px rgba(#ddd,0.4);
    }
    &.margin-small
    {
        margin: 6px;
    }
    i
    {
        margin-left: 10px;
    }
}
