.obat-page__contact
{
    h1
    {
        color: $color-primary !important;
        font-size: 6em !important;
        font-family: $font-primary;
    }
    .contact-cta
    {
        margin: 20px 0px;
    }
    .contact-cta .contact-cta-info
    {
        margin: 50px 0px;
    }

    .contact-cta .contact-cta-info .contact-cta-info__item
    {
        margin: 5px;
        background-color: $color-primary-light;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        overflow: hidden;
        border-right: 50px !important;
        svg
        {
            height: 50px;
            width: auto;
            padding: 15px;
            background-color: lighten($color-primary, 40%);
        }
        h2
        {
            padding: 0px 20px;
        }
    }

    .contact-map
    {
        margin: 50px 0px 0px 0px;
    }
    .contact-map iframe
    {
        width: 100%;
        min-height: 400px;
    }
}
