// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-primary: $font-family-sans-serif;
$font-size-base: 0.9rem;
$font-size-regular: 1rem;
$font-size-heading: 1.8rem;
$font-size-super-heading: 2.9rem;
$font-size-icon: 1.8rem;
$line-height-base: 1.6;
$font-weight-bold: 600;

// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;
$white: #fff;

// font detail
@font-face {
    src: url("..//fonts/manrope-regular.ttf");
    font-family: font-primary-regular;
}
@font-face {
    src: url("../fonts/manrope-bold.ttf");
    font-family: font-primary-bold;
}
@font-face {
    src: url("../fonts/manrope-light.ttf");
    font-family: font-primary-light;
}
$font-secondary: Lato,sans-serif;

// color theme
$color-primary: #D2653D;
$color-secondary: #5233FF;
$color-primary-dark: #934d2b;
$color-secondary-light: #A69DFF;
$color-secondary-bright: #B8B8FF;
$color-bright: #F8F7FF;
$color-primary-light: #FFEEDD;

