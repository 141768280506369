.obat-features
{
    margin: 150px 0px;
    .feature-image
    {
        width: 70%;
    }

    h1
    {
        margin-bottom: 20px;
        font-family: $font-primary;
        font-weight: 200;
        color: $color-primary-dark;
        font-size: 2em;
    }
    .obat-features--list li
    {
        margin: 15px 0px;
    }
}

