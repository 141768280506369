.obat-social
{
    a
    {
        padding: 5px 10px;
        border-radius: 2px;
        margin-right: 5px;
        background: transparent;
    }
    a:hover
    {
        color: $color-secondary !important;
        background: #fff;
    }
}
