.obat-floating-menu
{
    position: fixed;
    bottom: 0px;
    right: 0px;
    button
    {
        box-shadow: 0px 0px 25px #888;
        border-right: 2px;
        border: 2px solid white;
        margin: 10px;
    }
}
