@media screen and (max-width:720px)
{
    .obat-nav .obat-nav--menu-mobile{
        display: block !important;
    }
    .obat-nav--menu-list
    {
        display: none !important;
    }

    .header__info .header__info-cta
    {
        flex-direction: column;
    }
    .obat-steps
    {
        margin-top: 200px;
    }
    .obat-steps__flash .flash_feature-brand
    {
        position: relative !important;
        width: 100% !important;
    }
    .obat-steps__flash .obat-steps__flash-content
    {
        width: 100% !important;
    }
}


@media screen and (max-width:820px)
{
    .obat-hero-header__body .header__clip .header__clip-target
    {
        display: none;
    }

    .obat-steps__flash
    {
        margin-top: 70px !important;
    }

    .obat-hero-header__body .header__info
    {
        width: 100% !important;
        left: 0px;
        justify-content: center !important;
    }

    .obat-hero-header__body .header__info-content
    {
        width: 90% !important;
    }

    .obat-hero-header__body .header__info .header__info-cta
    {
        width: 90%;
        display: flex;
        flex-direction: column;
        div[role='group']
        {
            min-width: 100% !important;
        }
    }

    .obat-nav .obat-nav--menu-list *
    {
        font-size: 0.9em !important;
        //display: none;
    }
}
