body,html
{
    margin:0px;
}
button
{
    border-radius: 2px !important;
}


@import "variables";
@import "component/main";
@import "layout";
