.obat-nav
{
    display: flex;
    justify-content: space-around;
    align-content: center;
    flex-wrap: wrap;
    width: 100%;
    position: relative;
    z-index: 3;
    ul
    {
        padding: 0px;
        list-style-type: none;
    }
    .obat-nav--upper-nav
    {
        display: block;
        width: 100%;
        padding: 10px;
        background: $color-primary;
        font-family: $font-primary;
        font-size: $font-size-base;
        color: $color-bright;
        text-align: center;
    }

    .obat-nav--main-nav
    {
        width: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        position: fixed;
        top: 41px
    }

    .obat-nav--brand
    {
        //width: 20%;
        min-width: 100px;
    }
    .obat-nav--brand img
    {
        height: 100px;
        width: auto;
    }

    .obat-nav--menu
    {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
    .obat-nav--menu a
    {
        padding: 20px;
        border-radius: 2px;
        text-decoration: none;
        //color: #333;
        font-family: $font-primary;
        font-size: $font-size-regular;
        font-weight: $font-weight-bold;
        transition: all 400ms;
        margin: 1px;
        color: $color-bright;
    }

    .obat-nav--menu li:hover a
    {
        background: $color-primary;
        color: $white;
    }

    .obat-nav--menu-mobile
    {
        display: none !important;
    }

    .obat-nav--menu li:hover .obat-nav--menu__sub-menu
    {
        display: block;
    }

    .obat-nav--menu__sub-menu
    {
        display: none;
        overflow: hidden;
        background: $color-primary;
        margin-left: 1px;
        margin-top: 15px;
        position: absolute;
        animation-name: fade-in;
        animation-duration: 300ms;
        animation-timing-function: ease-in;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
    }

    .obat-nav--menu__sub-menu li a
    {
        display: block;
        border-radius: 0px;
        text-align: left;
        padding: 15px 100px 15px 20px;
        color: $white;
    }
    .obat-nav--menu__sub-menu li a:hover
    {
        background: $color-primary-dark;
    }

    .obat-nav--menu .oba-nav--menu_block
    {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .obat-nav--menu li button
    {
        border: 0px;
        margin: 0px 1px;
        background: transparent;
        padding: 0px;
        padding: 10px 15px;
        color: $color-primary;
        font-size: $font-size-heading;
        cursor: pointer;
        transition: all 0.2s;
    }
    .obat-nav--menu li button:hover
    {
        transform: scale(1.2,1.2);
    }

    .obat-nav--menu .oba-nav--menu_block i
    {
        margin: 0px;
        font-size: 1.5em;
    }

    .obat-nav--cta-button
    {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-wrap: wrap;
    }
    .obat-nav--cta-button button
    {
        margin: 0px 2px;
    }

    .obat-nav--cta-button a
    {
        padding: 10px;
        text-decoration: none;
    }

    .obat-nav--main-nav__float
    {
        box-shadow: 0px 0px 50px rgba(0,0,0,0.3);
        margin-top: 0px;
        top: 0px;
        background: #fff;
        z-index: 9;
        animation-name: fade-in;
        animation-duration: 300ms;
        animation-timing-function: ease-in;
        animation-iteration-count: 1;
        .obat-nav--menu li a
        {
            color: $color-primary !important;
        }

        .obat-nav--menu li:hover a
        {
            color: $white !important;
        }
        //.obat-nav--menu__sub-menu li a
        //{
        //    color: #fff;
        //}
        //
        //.obat-nav--menu__sub-menu li a:hover
        //{
        //    color: white !important;
        //}
    }

    &.obat-nav--menu-mobile-list ul.obat-nav--menu
    {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 50px;
        a{
            text-align: center;
            width: 100% !important;
            color: $color-secondary;
        }
        a:hover
        {
            background: transparent;
        }
        li
        {
            width: 100% !important;
            display: flex;
            justify-content: center;
            flex-direction: column;
        }
        li:hover a
        {
            background: #eee;
        }
        ul.obat-nav--menu__sub-menu
        {
            position: relative;
            width: 100% !important;
            background: transparent;
            border: 1px solid $color-secondary;
        }
        ul.obat-nav--menu__sub-menu li a
        {
            padding: 10px 0px;
            text-align: center;
            background: transparent !important;
        }
        ul.obat-nav--menu__sub-menu li
        {
            background: transparent !important;
        }
    }
}

@keyframes fade-in
{
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}
