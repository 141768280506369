.obat-loading
{
    width: 100%;
    min-height: 300px;
    z-index: 9;
    h3
    {
        margin: 20px 0px;
    }
}
