.obat-footer
{
    background-color: $color-secondary;
    background-attachment: fixed;
    background-image: url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23d08100' fill-opacity='0.23'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    padding: 50px 0px;
    width: 100%;
    background-repeat: no-repeat;
    color: #fff;

    .brand-logo
    {
        height: 100px;
        padding: 10px;
        border-radius: 2px;
        background: rgba(255,255,255,0.2);
    }
    .brand-logo+p
    {
        font-size: 0.7em;
        margin-top: 20px;
    }

    ul
    {
        list-style-type: none;
        padding: 0px;
    }

    ul li
    {
        margin: 10px 0px;
        color: #fff;
        font-family: $font-primary;
        color: #ccc;
    }

    ul li a
    {
        transition: all 200ms;
        &:hover
        {
            color: #fff;
        }
    }

    .obat-footer__subscribe
    {
        border-top: 2px solid rgba($color-secondary-light,0.2);
        padding: 10px 0px;
        margin: 20px 0px;
        p
        {
            font-size: 0.8em;
            margin-top: 10px;
        }
    }
    input
    {
        color: #333;
    }
}
