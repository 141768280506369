.obat-steps
{
    margin: 50px 0px;
    h1,h2
    {
        font-weight: 100;
        color: $color-primary-dark;
        font-family: $font-primary;
        margin: 10px 0px;
        .text-center
        {
            text-align: center;
        }
    }

    .obat-steps__info
    {
        margin: 100px 0px;
    }
    .obat-steps__info-container
    {
        border: 2px solid #eee;
        box-shadow: 0px 0px 50px #eee;
    }

    .obat-steps__box-container
    {
        margin: 30px 0px;
    }

    .obat-steps__info-content
    {
        //grid-column: span 2;
        padding: 20px;
    }
    .obat-steps__info-content p
    {
        line-height: 1.9em;
    }

    .prerequisite
    {
        //grid-column: span 2;
        background: $color-bright;
        padding: 20px 0px;
        border-right: 4px;
    }

    .obat-steps__box-container .obat-steps__box
    {
        padding: 20px;
        text-align:center;
        line-height: 1.5rem;
        i{
            font-size: 3rem;
            background: rgba($color-secondary-light,0.1);
            color: rgba($color-primary,0.8);
            padding: 20px;
            border-radius: 50px;
        }
        .obat-steps__box-icon
        {
            margin: 20px 0px;
        }
        .obat-steps__box-heading
        {
            margin: 5px 0px;
            //font-size: 1.5em;
            font-family: $font-primary;
        }
        p
        {
            width: 70%;
            color: #666;
            font-size: 0.8em;
        }
    }
    .obat-steps__flash
    {
        width: 80%;
        margin: 0px auto;
        margin-top: 170px;
        position: relative;
        background-attachment: fixed;
        background-image: url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23d08100' fill-opacity='0.23'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
        border-top-right-radius: 200px;
        border-bottom-left-radius: 200px;
        .obat-steps__flash-content
        {
            width: 65%;
            padding: 15px;
            z-index: 2;
            h2{
                color: $color-secondary;
                font-weight: bold;
            }
        }
        .flash_feature-brand
        {
            left: 0px;
            z-index: 2;
            position: absolute;
            width: 35%;
            bottom: 0px;
        }
    }
    ul.plain
    {
        padding: 0px;
        list-style-type: none;
    }

    ul.plain
    {
        display: flex;
        flex-direction: row;
        margin-top: 20px;
    }

    ul.plain li
    {
        padding: 2px;
        display: inline-block;
    }

    .prerequisite ul li
    {
        text-align: center;
        //padding: 5px 20px;
        font-size: 0.9em;
        display: inline-block;
        border-right: 2px;
        margin: 5px 2px;
        border-radius: 50px;
        font-family: $font-primary;
    }
}
