$border-radius: 20px;
$box-shadow:
    0 2.8px 2.2px rgba(0, 0, 0, 0.024),
    0 6.7px 5.3px rgba(0, 0, 0, 0.028),
    0 12.5px 10px rgba(0, 0, 0, 0.00),
    0 02.3px 17.9px rgba(0, 0, 0, 0.072),
    0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 0px 80px rgba(0, 0, 0, 0.05);
$dark-background: #323457;

.obat-pricing
{
    .obat-pricing__grid
    {
        //padding: 20px;
        background: white;

        .obat-pricing__grid-form
        {
            height: 600px;
            padding: 15px;
            grid-row: span 2;
            border-radius: $border-radius;
            box-shadow: $box-shadow;
            input
            {
                margin-bottom: 10px;
                border-radius: $border-radius/3 !important;
            }
            button
            {
                border-radius: $border-radius/3 !important;
            }
        }
        .obat-pricing__grid-form .grid-form__box-forms
        {

        }
        .obat-pricing__grid-form .grid-form__box .grid-form__box-header
        {
            border-radius: $border-radius;
            padding: 20px;
            background-image: url("../../img/happy_feature.png");
            background-size: 30%;
            background-repeat: no-repeat;
            background-position: bottom right;
        }

        .obat-pricing__grid-info
        {
            padding: 30px;
            border-radius: $border-radius;
            //grid-row: span 2;
            box-shadow: $box-shadow;
        }
        .obat-pricing__grid-price
        {
            border-radius: $border-radius;
            //grid-column-start: 2;
            //grid-column-end: 3;
            //grid-column: span 3;
            //grid-row-start: 1;
            //grid-row-end: 1;

            //grid-row: span 2;
            box-shadow: $box-shadow;
            padding: 20px;
            .grid-price__item
            {
                padding: 10px;
                height: 100%;
                border-radius: $border-radius;
            }
            .grid-price__item h2
            {
                font-weight: 200;
            }
        }
        .obat-pricing__grid-feature-2
        {
            box-shadow: $box-shadow;
            border-radius: $border-radius;
            padding: 20px;
            min-widows: 200px;
        }

        .obat-pricing__grid-plan
        {
            background-color: $dark-background;
            grid-row: span 2;
            box-shadow: $box-shadow;
            border-radius: $border-radius;
            padding: 20px;
            min-widows: 200px;
            img
            {
                margin: 10px 0px;
                border-radius: $border-radius;
            }
            h2
            {
                color: #fff;
                margin: 10px 0px;
                font-weight: 100;
            }
        }
        button
        {
            border-radius: $border-radius;
        }
    }
}


@media only screen and (max-width: 480px) {
    .col {  margin: 1% 0 1% 0%; }
    .span_1_of_4, .span_2_of_4, .span_3_of_4, .span_4_of_4 { width: 100%; }
}
