.obat-faq
{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 20px 10px;
    margin: 150px 0px;

    .obat-faq__heading
    {
        font-family: $font-primary;
        font-weight: bold;
        text-align: center;
    }
    .obat-faq__heading h1
    {
        font-size: 3em;
        font-family: $font-primary;
        font-weight: 100;
        color: $color-primary-dark;
    }
    .obat-faq__heading ul
    {
        display: flex;
        list-style-type: none;
        padding: 10px;
        justify-content: space-around;
        flex-wrap: wrap;
        align-items: stretch;
        margin: 20px 0px;
    }
    .obat-faq__heading ul li.active button
    {
        background-color: #dedede;
        color: $color-primary;
    }

    .obat-faq__heading ul button
    {
        margin: 5px;
        background-color: transparent;
        //border: 4px solid #cacaca;
        border-radius: 2px !important;
        height: 100%;
        padding: 0px;
        width: 150px;
        font-weight: bold;
        transition: all 0.2s;
    }
    .obat-faq__heading ul button i
    {
        display: block;
        font-size: $font-size-icon;
    }
    .obat-faq__heading ul button:hover
    {
        background-color: #dfdfdf;
        color: $color-primary;
    }
    .obat-faq__heading-body
    {
        width: 70%;
        min-width: 320px;
    }
    .obat-faq__heading-body button
    {
        font-weight: bold;
        color: $color-primary;
        text-transform:uppercase;
    }
}

