.obat-form
{
    .obat-form-item
    {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 20px 0px;
    }
    .obat-form-item form
    {
        width: 100%;
        margin: 50px 0px;
    }
    form .group
    {
        margin: 10px 0px;
    }
    form button
    {
        margin-top: 10px;
    }
}


.obat-form__signature-pad
{
    border: 2px solid $color-primary-light;
    canvas
    {
        width: 100%;
        height: 200px;
        border-bottom: 1px solid $color-primary-light;
    }
    .obat-form__signature-pad--bunttons
    {
        display: flex;
        justify-content: center;
        margin-top: 10px;
        margin-bottom: 10px;
    }
}

.form-group
{
    display: block;
    margin: 5px 0px;
    .form-control
    {
        margin: 2px 0px;
        display: block;
        width: 100%;
        //box-shadow: 0px 0px 20px #eee;
        border: 2px solid #eee;
        transition: all 0.3s ease-out;
        border-radius: 4px;
        outline: 0px;
        padding: 15px;
    }
    .form-control::before, .form-control::after
    {
        box-sizing: border-box;
    }
    .form-control:focus
    {
        border: 2px solid $color-primary;
    }
}

.obat-form__render
{
    button[type='submit']
    {
        padding: 10px 20px;
        background-color: $color-primary;
        text-transform: uppercase;
        color: #fff;
        margin-top: 20px;
        margin-bottom: 20px;
        float: right;
    }
    .panel.panel-danger
    {
        background-color: orange;
        padding: 10px;
        ul
        {
            list-style-type: none;
        }
    }
    fieldset .field-object
    {
        margin: 20px 0px;
    }
    fieldset .field-object legend
    {
        margin: 0px auto;
        //color: $color-secondary;
        padding: 5px 0px;
        border-bottom: 2px solid #666;
    }
}
